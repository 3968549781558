.quick-sub-heading-style {
  margin-top: 8px;
  color: #000;
  font-family: Mitr;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
/* .follow-links {
  margin-top: 16px;
  display: flex;
  gap: 14px;
  justify-content: center;
  align-items: center;
} */
.terms-policy {
  margin-top: 18px;
  color: #000;
  font-family: Mitr;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
