.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* annnimation */
/* see projects */
.projects-content {
  max-width: 1100px;
  margin: 0% auto;
  width: 100%;
}

.work-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 40px;
  margin-top: 50px;
}
.work {
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}
.work img {
  width: 100%;
  border-radius: 10px;
  display: block;
}
.layer {
  width: 100%;
  height: 0%;
  background: linear-gradient(rgba(0, 0, 0, 0.6), #f43e04);
  border-radius: 10px;
  position: absolute;
  left: 0;
  bottom: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 40px;
  text-align: center;
  transition: 0.8s;
}
.work:hover .layer {
  height: 100%;
}
.layer h3 {
  font-weight: 500;
}
.layer p {
  color: #f0f0f0;
}
.ex-link {
  margin-top: 20px;
  color: #f43e04;
  text-decoration: none;
  font-size: 18px;
  line-height: 60px;
  background: #fff;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  text-align: center;
}
