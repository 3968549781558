.contact-form {
  display: block;
  text-align: start;
}
.form-title-style {
  color: #000;
  text-align: center;
  font-family: Lexend;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

input {
  margin-top: 0%;
  width: 100%;
  border: 0;
  outline: 0;
  border-bottom: 2px solid #bfbfbf;
}
