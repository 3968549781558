.types-bg {
  background-color: #e6e6e6;
  border-radius: 24px;
  display: flex;
  padding-left: 20px;
  align-items: center;
  height: 100px;
  padding-right: 20px;
  gap: 15px;
}
.set-bg-circle {
  height: 64px;
  width: 64px;
  display: flex;
  justify-content: center;
  border-radius: 100%;
  background-color: #f43e04;
}
.s .explanation {
  margin-top: 70px;
  margin-left: 4%;
  margin-right: 5%;
}
