@import url('https://fonts.google.com/specimen/mitr?query=mitr');
@import url('https://fonts.google.com/?query=michroma');
@import url('https://fonts.google.com/specimen/Lexend?query=lexend');
@import url('https://fonts.googleapis.com/css2?family=Mitr&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Michroma&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lexend&display=swap');
/* .............................Header..................... */
.header-style {
  height: 96px;
  width: 100%;
  background: #fff;
  box-shadow: 3px 4px 20px 0px rgba(0, 0, 0, 0.1);
}
.top-flag-style {
  height: 12px;
  width: 100%;
  background-color: #f43e04;
}

.header-components {
  height: 84px;
  display: flex;
  margin-left: 63px;
  margin-right: 63px;
  justify-content: space-between;
  align-items: center;
}
.app-services {
  display: flex;
  gap: 50px;
  align-items: center;
  justify-content: space-evenly;
}
.app-services-style {
  color: #000;
  font-family: Mitr, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.apply-btn {
  height: auto;
  width: auto;
  text-align: center;
  padding: 10px;
  background-color: #f43e04;
  color: #fff;
  font-family: Michroma, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  max-width: 120px;
  border-radius: 10px;
}

/* ................................................Header-content...................... */

.selected-section {
  margin-top: 4px;
  width: 64px;
  height: 4px;
  flex-shrink: 0;
  border-radius: 6px;
  background: #f43e04;
  box-shadow: 0px 4px 13px 0px #f43e04;
}

.wrapper {
  height: 70%;
}
