.services-bg {
  background-color: white;
  height: max-content;
}
.heading-text-style {
  color: #000;
  font-family: Mitr;
  font-size: 50px;
  margin-top: 32px;
  text-align: center;
  font-style: normal;
  font-weight: 400;
  line-height: 73px; /* 135.185% */
}

.feature-type-heading {
  color: #000;
  font-family: Mitr;
  font-size: 32px;
  margin-top: 52px;
  text-align: start;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.feature-type-subheading {
  color: #000;
  font-family: Mitr;
  margin-top: 52px;
  font-size: 24px;
  font-style: normal;
  text-align: start;
  font-weight: 300;
  line-height: normal;
}

.Subtract1:hover {
  background-color: #f43e04;
}

.Subtract2:hover {
  background-color: #f43e04;
}

.Subtract3:hover {
  background-color: #f43e04;
}
.Component1:hover {
  background-color: #f43e04;
}
.Component2:hover {
  background-color: #f43e04;
}
.Component3:hover {
  background-color: #f43e04;
}

.Ellipse2 {
  height: 144px;
  width: 144px;
  border-radius: 100%;
  background-color: #e6e6e6;
  border: 4px solid white;
}
.Ellipse3 {
  height: 144px;
  width: 144px;
  border-radius: 100%;
  background-color: #e6e6e6;
  border: 4px solid white;
}
